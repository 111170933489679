<template>
  <div class="caresheets">

    <!-- Header -->
    <ObjectPageHeader
      v-bind:objectName="objectName"
      v-on:refreshData="refreshData($event)">
    </ObjectPageHeader>

    <!-- Info Tiles -->
    <div class="row">
      <div
        class="flex xs12 md3"
        v-for="(info, idx) in infoTiles"
        :key="idx"
      >
        <va-card class="mb-4" :color="info.color">
          <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
          <p>{{info.text}}</p>
        </va-card>
      </div>

      <div class="flex xs12 md5">
        <va-card class="mb-4" color="orange">
          <p v-on:click="createCaresheet" class="display-2 mb-0" style="color: white;">Create New Caresheet</p>
          <p v-on:click="createCaresheet">Need more CareSheets? <u>Click here</u> to start</p>
        </va-card>
      </div>
    </div>

    <!-- Data table -->
    <va-card v-if="!showCaresheet" title="All Caresheets">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Caresheet name"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="actions" slot-scope="props">
          <va-popover :message="`View ${props.rowData.cid} Live on site`" placement="left">
            <va-button flat small color="dark" icon="fa fa-eye" v-on:click="openCaresheet(props.rowData, props.rowIndex)"/>
          </va-popover>

          <va-popover :message="`Edit Caresheet ${props.rowData.cid}`" placement="left">
            <va-button flat small color="info" icon="fa fa-pencil" v-on:click="editCaresheet(props.rowData)"/>
          </va-popover>

          <va-popover :message="`${$t('tables.delete')} Caresheet ${props.rowData.cid}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteCaresheet(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

    <CaresheetPage v-else
      v-bind:mode="mode"
      v-bind:caresheet="selectedCaresheet"
      v-on:saveCaresheet="saveCaresheet()"
      v-on:goBack="goBack()">
    </CaresheetPage>

  </div>

</template>

<script>
import { debounce } from 'lodash'
import { caresheetCollection } from '@/firebaseConfig.js'
import CaresheetPage from '@/components_tc/caresheets/CaresheetPage.vue'
import ObjectPageHeader from '@/components_tc/utilities/ObjectPageHeader.vue'

export default {
  name: 'caresheets',
  components: {
    ObjectPageHeader,
    CaresheetPage,
  },
  data () {
    return {
      term: null,
      mode: '',
      perPage: '10',
      careSheets: [],
      objectName: '',
      loading: false,
      showCaresheet: false,
      selectedCaresheet: {},
      perPageOptions: ['10', '25', '50', '100'],
      infoTiles: [{
        color: 'secondary',
        value: '0',
        text: 'Published Caresheets',
        icon: '',
      }],
    }
  },

  computed: {
    Caresheets: function () {
      return this.$store.state.caresheets
    },
    fields () {
      return [{
        name: 'title',
        title: this.$t('Caresheet'),
        sortField: 'title',
        width: '30%',
      }, {
        name: 'cid',
        title: this.$t('Caresheet ID'),
        sortField: 'cid',
        width: '25%',
      }, {
        name: 'author',
        title: this.$t('Author'),
        sortField: 'author',
        width: '20%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.careSheets
      }

      return this.careSheets.filter(item => {
        return item.title.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    refreshData: function (event) {
      this.getCaresheetData(event)
    },

    goBack: function () {
      this.showCaresheet = false
    },

    openCaresheet: function (caresheetData) {
      window.open(
        'https://www.turtlecreek.ie/caresheet/' +
        caresheetData.cid,
        '_blank',
      )
    },

    editCaresheet: function (caresheetData) {
      this.mode = 'edit'
      this.selectedCaresheet = caresheetData
      this.showCaresheet = true
    },

    deleteCaresheet: function (caresheetData, caresheetIndex) {
      const _this = this
      this.loading = true

      caresheetCollection
        .doc(caresheetData.cid)
        .delete()
        .then(function () {
          _this.deleteFromTable(caresheetData, caresheetIndex)
          this.showToast(
            'Caresheet Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (caresheetData, caresheetIndex) {
      let realIndex
      const caresheetIndexRev = (this.careSheets.length - 1) - caresheetIndex

      if (caresheetData.cid === this.careSheets[caresheetIndex].cid) {
        realIndex = caresheetIndex
      } else if (caresheetData.cid === this.careSheets[caresheetIndexRev].cid) {
        realIndex = caresheetIndexRev
      }

      this.careSheets.splice(realIndex, 1)
      this.$store.commit('setCaresheets', this.careSheets)
      this.loading = false
    },

    saveCaresheet () {
      this.showToast(
        'Caresheet Saved',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    createCaresheet: function () {
      this.mode = 'create'
      this.selectedCaresheet = {
        author: '',
        authorPhoto: '',
        authorRole: '',
        cid: '',
        title: '',
        intro: '',
        photo: '',
        habitat: '',
        diet: '',
        health: '',
        breeding: '',
        references: '',
        tags: [],
      }
      this.showCaresheet = true
    },

    getCaresheetData: function (event) {
      var _this = this
      this.loading = true

      if (this.Caresheets === undefined || this.Caresheets.length === 0 || event != null) {
        caresheetCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonCaresheets = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              jsonCaresheets.push(itemData)
            })

            _this.careSheets = jsonCaresheets.sort((a, b) => (a.title > b.title) ? 1 : -1)
            _this.$store.commit('setCaresheets', _this.careSheets)
            _this.updateInfoTiles(_this.Caresheets)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.careSheets = this.Caresheets
        this.updateInfoTiles(this.Caresheets)
        this.loading = false
      }
    },

    updateInfoTiles: function (totalCaresheets) {
      this.infoTiles[0].value = totalCaresheets.length
    },

  },

  mounted () {
    this.objectName = this.$options.name
    this.getCaresheetData(null)
  },

}
</script>
