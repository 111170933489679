<template>
  <div class="caresheetPage">
    <!-- caresheet Form -->
    <form @submit.prevent="saveChanges">
      <va-card>

        <!-- Controls -->
        <div class="row">
          <div class="flex xs6 md10">
            <h1 class="display--1 text--capitalize">{{ mode }} Caresheet Form</h1>
          </div>
          <div class="flex xs6 md2">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>
        </div>

        <!-- Row 1 -->
        <div class="row">
          <!-- caresheet title -->
          <div class="flex md8 xs12">
            <va-input
              label="Title"
              v-model="caresheet.title"
              placeholder="Type title here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-header"
              />
            </va-input>
          </div>

          <!-- Caresheet id -->
          <div class="flex md4 xs12">
            <va-input
              label="Caresheet ID"
              v-model="caresheet.cid"
              v-bind:disabled="mode === 'edit'"
              placeholder="Type Caresheet ID here"
            >
              <va-icon
                slot="prepend"
                v-bind:color="mode === 'edit' ? 'gray' : 'success'"
                name="fa fa-file-text"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">

          <!-- LEFT SIDE -->
          <div class="flex md8 xs12">

            <!-- Tags -->
            <va-input
              label="Tags"
              v-model="newTag"
              type="text"
              v-on:keydown.enter.prevent='addTag'
              placeholder="Type Tags here"
            >
              <va-icon
                slot="prepend"
                name="fa fa-tag"
                color="success"
              />
            </va-input>

            <va-card>
              <div class="row">
                <div v-for="(tag, index) in caresheet.tags"
                  :key="index"
                  class="tag">
                  {{ tag }}
                  <va-button
                    flat
                    small
                    medium
                    color="primary"
                    icon="fa fa-times"
                    type="button"
                    v-on:click="removeTag(index)"/>

                </div>
              </div>
            </va-card>
          </div>

          <!-- RIGHT SIDE -->
          <div class="flex md4 xs12">

            <!-- Author -->
            <va-input
              label="Author"
              v-model="caresheet.author"
              placeholder="Type Author here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-pencil"
              />
            </va-input>

            <!-- Author Role -->
            <va-input
              label="Author Role"
              v-model="caresheet.authorRole"
              placeholder="Type Author Role here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-vcard"
              />
            </va-input>

            <!-- Author Photo-->
            <va-input
              label="Author Photo"
              v-model="caresheet.authorPhoto"
              placeholder="Type Author Photo here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-smile-o"
              />
            </va-input>
          </div>
        </div>

        <!-- Intro -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">Introduction</h6>
            <textarea
              id="Intro"
              rows="15"
              cols="75"
              placeholder="Type Intro Here"
              v-model="caresheet.intro">
            </textarea>
          </div>
        </div>

        <!-- Intro -->
        <div class="row">
          <div class="flex md8 xs12">
            <va-input
              label="Intro Photo"
              v-model="caresheet.photo"
              placeholder="Type Intro Photo here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-photo"
              />
            </va-input>
          </div>
        </div>

        <!-- Habitat and Housing -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">Habitat and Housing</h6>
            <textarea
              id="Habitat"
              rows="20"
              cols="75"
              placeholder="Type Habitat Here"
              v-model="caresheet.habitat">
            </textarea>
          </div>
        </div>

        <!-- Feeding and Diet -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">Feeding and Diet</h6>
            <textarea
              id="Diet"
              rows="15"
              cols="75"
              placeholder="Type Diet Here"
              v-model="caresheet.diet">
            </textarea>
          </div>
        </div>

        <!-- Health Information -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">Health Info</h6>
            <textarea
              id="Health Information"
              rows="15"
              cols="75"
              placeholder="Type Health Info Here"
              v-model="caresheet.health">
            </textarea>
          </div>
        </div>

        <!-- Sexing and Breeding -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">Sexing and Breeding Info</h6>
            <textarea
              id="Breeding"
              rows="15"
              cols="75"
              placeholder="Type Sexing and Breeding Info Here"
              v-model="caresheet.breeding">
            </textarea>
          </div>
        </div>

        <!-- References and Further reading -->
        <div class="row">
          <div class="flex md8 xs12">
            <h6 class="display--6">References and Further reading</h6>
            <textarea
              id="References"
              rows="10"
              cols="75"
              placeholder="Type References Here"
              v-model="caresheet.references">
            </textarea>
          </div>
        </div>

        <!-- Controls Bottom -->
        <div class="row">

          <!-- Go Back -->
          <div class="flex xs12 md9">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>

          <!-- Save form -->
          <div class="flex xs12 md3">
            <va-button
              medium
              color="info"
              type="submit"
              v-on:click="saveChanges">
              Save Caresheet
            </va-button>
          </div>
        </div>
      </va-card>
    </form>
  </div>
</template>

<script>
import { caresheetCollection } from '@/firebaseConfig.js'

export default {
  name: 'caresheetPage',
  props: ['caresheet', 'mode'],
  data () {
    return {
      newTag: '',
    }
  },

  methods: {
    addTag: function () {
      this.caresheet.tags.push(this.newTag)
      this.newTag = ''
    },

    removeTag: function (index) {
      this.caresheet.tags.splice(index, 1)
    },

    goBack: function () {
      this.$emit('goBack')
    },

    saveChanges: function () {
      const _this = this
      caresheetCollection
        .doc(this.caresheet.cid)
        .set(this.caresheet)
        .then(() => {
          _this.$emit('saveCaresheet')
          _this.goBack()
        })
    },
  },
}
</script>

<style>
.tag {
  margin: 1px 2px;
  padding: 0 10px;
  color: #40e583;
  border: #2c82e0;
  border-style: solid;
  font-weight: bold;
  background-color: #13559c;
  font-size: 80%;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 25px;
}

textarea {
  width: 100%;
  border: 1px solid #9ba4b4;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
}

</style>
